import { customElement } from 'solid-element';
import { lazy } from 'solid-js';
import { isInViewport } from 'utils/in-viewport';

export default function DiscordWidget() {
  return (
    <iframe
      src="https://discord.com/widget?id=1167897268318384170&theme=dark"
      width="100%"
      height="250"
      // @ts-ignore
      allowTransparency="true"
      frameBorder="0"
      loading="lazy"
      sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
    />
  );
}

customElement('discord-widget-component', {}, (props, { element }) => {
  return lazy(async () => {
    await new Promise((res) =>
      document.addEventListener('scroll', () => {
        if (isInViewport(element as any)) {
          res(null);
        }
      })
    );
    return import(import.meta.url);
  });
});
